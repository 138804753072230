class ZIDXAccountListingIndexFileEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountListingIndexFileEditContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/listing-index/file/edit";
    }
    render(){
        // console.log("listing index file edit");
    }
}